import React, { useEffect, useState } from "react";

import Lottie from "react-lottie";
import animationAssistantFetching from "./fetching_animation.json";
import animationAssistantIdle from "./idle_animation.json";

export default function Assistant(props) {
  const { handleLoader, isContactForm } = props;
  const [assistantScale, setAssistantScale] = useState(0.6);
  const [assistantPosition, setAssistantPosition] = useState({
    bottom: "0%",
    right: "50%",
    transform: "translate(0%,0%)",
    opacity: "1",
    position: "fixed"
  });
  useEffect(() => {
    setTimeout(handleLoader, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [assitantTransition, setAssistantTransition] =
    useState("all 0.6s ease");
  const [loaderTextAnimation, setLoaderTextAnimation] = useState({
    opacity: "1",
    bottom: "42%",
    delay: "1"
  });
  const [assistantState, setAssistantState] = useState(animationAssistantIdle);
  const [hoverState, setHoverState] = useState({
    scale: 1,
    pointerEvents: "auto"
  });
  const [overlay, setOverlay] = useState({ background: "none" });
  const [minWidth, setMinWidth] = useState({ width: "100%" });
  const [minHeight, setMinHeight] = useState({ height: "100%" });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: assistantState,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const assistantWrapper = {
    position: `${assistantPosition.position}`,
    width: `${minWidth.width}`,
    height: `${minHeight.height}`,
    background: overlay.background,
    bottom: `${assistantPosition.bottom}`,
    right: `${assistantPosition.right}`,
    transform: `${assistantPosition.transform}`,
    opacity: `${assistantPosition.opacity}`,
    transition: `${assitantTransition}`
  };

  const assistantContainer = {
    position: "absolute",
    bottom: `${assistantPosition.bottom}`,
    right: `${assistantPosition.right}`,
    transform: `${assistantPosition.transform}`,
    opacity: `${assistantPosition.opacity}`,
    transition: `${assitantTransition}`
  };

  const assistantEffects = {
    filter: "drop-shadow(0px 30px 20px rgba(0,0,255,0.2))",
    transform: `scale(${hoverState.scale})`,
    transition: "all 0.4s",
    pointerEvents: `${hoverState.pointerEvents}`,
    cursor: "pointer"
  };

  const loaderText = {
    position: "fixed",
    bottom: `${loaderTextAnimation.bottom}`,
    left: "50%",
    transform: "translate(-50%,75%)",
    fontSize: "14px",
    color: `${isContactForm ? "white" : "#8F90A6"}`,
    opacity: `${loaderTextAnimation.opacity}`,
    transition: "all 0.4s ease-out",
    transitionDelay: `${loaderTextAnimation.delay}`,
    pointerEvents: "none"
  };

  useEffect(() => {
    if (props.animation === "animateToBottom") {
      animateToBottom();
    } else if (props.animation === "animateToCenter") {
      animateToCenter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.animation]);
  const animateToBottom = () => {
    setLoaderTextAnimation({ opacity: "1", bottom: "40%", delay: "0s" });

    //final position to bottom right
    let timeOut1 = setTimeout(() => {
      setOverlay({ background: "none" });
      setAssistantTransition("all 0.6s ease");
      setAssistantScale(0.6);
      setAssistantPosition({
        bottom: "0%",
        right: "0%",
        transform: "translate(0%,0%)",
        opacity: "1",
        position: "fixed"
      });
      setAssistantState(animationAssistantIdle);
      setHoverState({ pointerEvents: "auto" });
      setMinHeight({ height: "100px" });

      setMinWidth({ width: "100px" });
    }, 300);
    clearTimeout(timeOut1);
  };

  const animateToCenter = () => {
    //center without transition
    setOverlay({ background: props.overlayColor });
    setHoverState({ pointerEvents: "none" });
    setAssistantTransition("none");
    setAssistantScale(1);
    setAssistantPosition({
      bottom: "50%",
      right: "50%",
      transform: "translate(50%,50%)",
      opacity: "1"
    });
    setLoaderTextAnimation({ opacity: "1", bottom: "42%", delay: "1s" });

    //final position to center
    setAssistantState(animationAssistantFetching);

    let timeOut2 = setTimeout(() => {
      setAssistantTransition("all 0.6s ease");
      setAssistantPosition({
        bottom: "50%",
        right: "50%",
        transform: "translate(50%,50%)",
        opacity: "1"
      });
    }, 600);
    clearTimeout(timeOut2);
  };

  //handle hover states when idle
  const toggleHoverState = e => {
    if (props.animation === "animateToBottom") {
      if (e.type === "mouseover") {
        setHoverState({ scale: 1.2 });
      } else if (e.type === "mouseout") {
        setHoverState({ scale: 1 });
      }
    }
  };

  return (
    <div style={assistantWrapper}>
      <div
        style={assistantContainer}
        onMouseOverCapture={e => toggleHoverState(e)}
        onMouseOutCapture={e => toggleHoverState(e)}>
        <div style={assistantEffects}>
          <Lottie
            options={defaultOptions}
            height={200 * assistantScale}
            width={200 * assistantScale}
          />
        </div>
      </div>
      <div style={loaderText}>
        {isContactForm ? <p>sending...</p> : <p>loading...</p>}
      </div>
    </div>
  );
}
