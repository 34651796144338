import styled, { keyframes } from "styled-components";

const LoaderAnimation = keyframes`
  0%{background:transparent;color:transparent}
  25% {transform:scale(0)}
  50% {
    left: 100%;
    top: 100%;
    margin-right:30px;
  }
  100%
  {
    display:none;animation-play-state: paused;
  }
`;

const LoaderAnimationMobile = keyframes`
  0%{background:transparent;color:transparent}
  25% {transform:scale(0)}
  50% {
    top: 100%;
  }
  100%
  {
    display:none;animation-play-state: paused;
  }
`;

export const AuthLoaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColor.loaderBgColor};
  z-index: 100;
  display: flex;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColor.loaderBgColor};
  z-index: 100;
  display: flex;
  align-items: center;
  animation-name: ${LoaderAnimation};
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-delay: 2s;
  transition-delay: 2s;
  animation-play-state: ${props => (props.showAnime ? "paused" : "play")};
  ${props => `@media ${props.theme.breakPoints.tabDevice}`} {
    animation-name: ${LoaderAnimationMobile};
  }
  ${props => `@media ${props.theme.breakPoints.mobileDevice}`} {
    animation-name: ${LoaderAnimationMobile};
  }
`;

export const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

